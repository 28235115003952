const TOGGLE_CART = 'TOGGLE_CART'
const TOGGLE_MENU = 'TOGGLE_MENU'
const OPEN_CART = 'OPEN_CART'
const CLOSE_CART = 'CLOSE_CART'
const OPEN_MENU = 'OPEN_MENU'
const CLOSE_MENU = 'CLOSE_MENU'
const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
const ACTIVATE_GRID_VIEW = 'ACTIVATE_GRID_VIEW'
const ACTIVATE_LIST_VIEW = 'ACTIVATE_LIST_VIEW'
const UPDATE_FILTERS = 'UPDATE_FILTERS'
const FILTER_PRODUCTS = 'FILTER_PRODUCTS'
const CLEAR_FILTERS = 'CLEAR_FILTERS'
const SORT_ASC = 'SORT_ASC'
const SORT_DEC = 'SORT_DEC'
const SORT_FET = 'SORT_FET'

export {
  TOGGLE_CART,
  TOGGLE_MENU,
  OPEN_CART,
  CLOSE_CART,
  OPEN_MENU,
  CLOSE_MENU,
  FETCH_PRODUCTS,
  ACTIVATE_GRID_VIEW,
  ACTIVATE_LIST_VIEW,
  UPDATE_FILTERS,
  FILTER_PRODUCTS,
  CLEAR_FILTERS,
  SORT_ASC,
  SORT_DEC,
  SORT_FET,
}
