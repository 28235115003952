import React, { createContext, useContext, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Client from 'shopify-buy'
import fetch from 'isomorphic-fetch'

const initialValues = {
  cart: [],
  loading: false,
  addVariantToCart: (variantId, quantity) => {},
  removeLineItem: (id: string, itemId: string) => {},
  updateLineItem: (id: string, itemId: string, value: string) => {},
  client: {},
  checkout: {
    id: '',
    webUrl: '',
    lineItems: [],
    totalTaxV2: {
      currencyCode: '',
      amount: 0,
    },
    totalPriceV2: {
      currencyCode: '',
      amount: 0,
    },
  },
  didJustAddToCart: false,
}

const StoreContext = createContext(initialValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query CoreOptionsQuery {
      coreOptions(id: { eq: "gatsby-theme-shopify-manager" }) {
        shopName
        accessToken
      }
    }
  `)

  const client = Client.buildClient({
    domain: data.coreOptions.shopName,
    storefrontAccessToken: data.coreOptions.accessToken,
  })

  const [checkout, setCheckout] = useState(initialValues.checkout)
  const [loading, setLoading] = useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)

  const setCheckoutItem = checkout => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser ? localStorage.getItem(localStorageKey) : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout: any = await client.checkout.fetch(existingCheckoutID)
          if (!existingCheckout?.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate: any = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    return client.checkout.addLineItems(checkoutID, lineItemsToUpdate).then((res: any) => {
      setCheckout(res)
      setLoading(false)
      setDidJustAddToCart(true)
      setTimeout(() => setDidJustAddToCart(false), 3000)
    })
  }

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    return client.checkout.removeLineItems(checkoutID, [lineItemID]).then((res: any) => {
      setCheckout(res)
      setLoading(false)
    })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity, 10) }]

    return client?.checkout.updateLineItem(checkoutID, lineItemsToUpdate).then((res: any) => {
      setCheckout(res)
      setLoading(false)
    })
  }

  return (
    <StoreContext.Provider
      value={{
        ...initialValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
        client,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export const useStoreContext = () => useContext(StoreContext)
